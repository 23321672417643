<div class='layout-navbar'>
	<div class='flex flex-1 gap-2 align-items-center justify-content-start'>
		    <img ngSrc='/assets/images/wokap-logo.svg' alt='logo' height='40' width='40' />
		    <span class='font-bold text-xl pl-2'>{{ title }}</span>
	</div>

	<!-- Desktop -->
	<div class='hidden md:flex flex-1 gap-5 justify-content-center'>
		@for (navbarItem of navbarItems; track $index) {
			<p-button
					[label]='navbarItem.label | translate'
					[severity]='navbarItem.active ? "primary" : "secondary"'
					[rounded]='true'
					size='small'
					(click)='navigate(navbarItem.routerLink)' />
		}
	</div>
	<!-- Mobile -->
	<div class='flex flex-1 md:hidden gap-2 justify-content-center'>
		@for (navbarItem of navbarItems; track $index) {
			<p-button
					[icon]='navbarItem.icon'
					[severity]='navbarItem.active ? "primary" : "secondary"'
					[rounded]='true'
					size='small'
					(click)='navigate(navbarItem.routerLink)' />
		}
	</div>

	<div class='flex flex-1 gap-2 align-items-center justify-content-end'>
		<span class='font-semibold hidden lg:block'>{{ getUsername() }}</span>
		<p-button icon='pi pi-briefcase' [rounded]='true' severity='secondary' (onClick)='onSidebarMenuToggle()'>
			<p-image
					class='absolute top-0 left-0 bg-white'
					[src]='companyMedia()?.squareSmall'
					alt='company logo'
					width='48' />
		</p-button>
	</div>
</div>

<p-sidebar [(visible)]='sidebarMenuVisible' [showCloseIcon]='false' position='right' styleClass='w-25rem'>
	<ng-template pTemplate='header'>
		<div class='flex flex-1 justify-content-between align-items-center'>
			<span>
					<p-button pRipple [text]='true' severity='secondary' type='button' (onClick)='closeSidebarMenu()' icon='pi pi-times' rounded='true' styleClass='p-sidebar-close p-sidebar-icon p-link'></p-button>
			</span>
			<div class='flex flex-1 gap-2 align-items-center justify-content-end'>
				<span class='font-semibold lg:block'>{{ getUsername() }}</span>
				<p-button icon='pi pi-briefcase' [rounded]='true' severity='secondary' (onClick)='onSidebarMenuToggle()'>
					<p-image
							class='absolute top-0 left-0 bg-white'
							[src]='companyMedia()?.squareSmall'
							alt='company logo'
							width='48' />
				</p-button>
			</div>
		</div>
	</ng-template>
	<p-panelMenu [model]='profileMenuItems' styleClass='w-full pt-3'>
		<ng-template pTemplate='item' let-item>
			<div class='flex align-items-center justify-content-between px-3 py-4 cursor-pointer'>
				<div class='flex align-items-center'>
					@if (item.flag) {
						<img [ngSrc]='item.flag' height='20' width='20' alt='wokap' />
					} @else {
						<i [class]='item.icon'></i>
					}
					<span class='ml-3'>
					{{ item.label+ '' | translate }}
				</span>
				</div>
                <i *ngIf='item.items' class='pi pi-angle-down ml-auto'></i>

			</div>
		</ng-template>
	</p-panelMenu>
	<div class='flex justify-content-end flex-wrap'>
		<p-button [text]='true' [plain]='true' (click)='logout()' styleClass='flex pt-4'>
			<i class='pi pi-download pi-rotate-90'></i>
			<span class='ml-3'>
				{{'LAYOUT.NAVBAR.LOGOUT' | translate}}
			</span>
		</p-button>
	</div>
	<div class='absolute bottom-0 left-0 p-3'>{{ version }}</div>
</p-sidebar>
