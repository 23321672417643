import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CreateMutationResult } from '@tanstack/angular-query-experimental';
import { MenuItem } from 'primeng/api';
import { Media } from '@/common/models';
import { MessageService } from '@/common/services';
import { LayoutService } from '@/layout/services/layout.service';
import { User } from '@/pages/auth/models';
import { AuthService } from '@/pages/auth/services/auth.service';
import { environment } from '@environments/environment';
import appVersion from '../../../../../app_version.json';

interface NavbarItem {
  id: string;
  label: string;
  routerLink: string[];
  active?: boolean;
  icon: string;
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
  version = 'v' + appVersion.version + '-' + environment.appEnv;
  profileMenuItems: MenuItem[];
  navbarItems: NavbarItem[];
  title: string;

  @ViewChild('menuButton') menuButton!: ElementRef;
  @ViewChild('navbarMenuButton') navbarMenuButton!: ElementRef;
  @ViewChild('navbarMenu') menu!: ElementRef;

  readonly sidebarMenuVisible: WritableSignal<boolean> = signal<boolean>(false);

  readonly companyMedia: WritableSignal<Media | null | undefined> = signal<Media | null | undefined>(null);
  readonly companyName: WritableSignal<string> = signal<string>('');
  readonly currentRoute: WritableSignal<string> = signal<string>('');

  readonly updateUserProfile: CreateMutationResult<Partial<User>, HttpErrorResponse, User> = this._authService.updateUserProfile();

  constructor(
    private _activatedRoute: ActivatedRoute,
    protected layoutService: LayoutService,
    private _translate: TranslateService,
    private _authService: AuthService,
    private _router: Router,
    private _messageService: MessageService,
  ) {
  }

  ngOnInit(): void {
    this.companyMedia.set(this._authService.getUser()?.entity.media);
    this.navbarItems = [
      // {
      //   id: 'dashboard',
      //   label: this._translate.instant('DASHBOARD.TITLE'),
      //   routerLink: ['/dashboard'],
      //   icon: 'pi pi-home',
      // },
      {
        id: 'offers',
        label: 'OFFERS.TITLE',
        routerLink: ['/offers'],
        icon: 'pi pi-list',
      },
      {
        id: 'chat',
        label: 'CHAT.TITLE',
        routerLink: ['/chat'],
        icon: 'pi pi-comments',
      },
    ];

    this.profileMenuItems = [
      {
        id: 'change-password',
        label: 'LAYOUT.NAVBAR.CHANGE_PASSWORD',
        // icon: 'pi pi-lock',
        command: () => {
          this._router.navigate(['/auth/change-password']);
          this.sidebarMenuVisible.set(false);
        },
      },
      {
        id: 'language',
        label: 'LAYOUT.NAVBAR.LANGUAGE',
        icon: 'pi pi-language',
        items: [
          {
            label: 'LAYOUT.NAVBAR.LANGUAGES.EN',
            flag: '/assets/images/icons/flag/uk-flag.svg',
            command: () => {
              this._translate.use('en');
              this.updateLocale('en');
            },
          },
          {
            label: 'LAYOUT.NAVBAR.LANGUAGES.ES',
            flag: '/assets/images/icons/flag/spain-flag.svg',
            command: () => {
              this._translate.use('es');
              this.updateLocale('es');
            },
          },
        ],
      },
      {
        id: 'company',
        label: 'LAYOUT.NAVBAR.COMPANY',
        icon: 'pi pi-briefcase',
        command: () => {
          this._router.navigate(['/company/edit']);
          this.sidebarMenuVisible.set(false);
        },
      },
    ];

    this.companyMedia.set(this._authService.getUser()?.entity.media);
    this.companyName.set(this._authService.getUser()?.entity.name ?? '');

    // Subscribe to route changes
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute.set(event.urlAfterRedirects);
        this.updateActiveNavbarItems();
      }
    });

    // Initial calls
    this.updateActiveNavbarItems();
    this.currentRoute.set(this._router.url);
  }

  logout(): void {
    this._authService.logout();
  }

  closeSidebarMenu(): void {
    this.sidebarMenuVisible.set(false);
  }

  // updateActiveNavbarItems(): void {
  //   console.log(this._activatedRoute.children);
  //   this._activatedRoute.firstChild?.data.subscribe(data => {
  //     // Active navbar item
  //     this.navbarItems?.forEach(item => {
  //       item.active = data['navbarItem'] === item.id;
  //     });
  //
  //     // Title
  //     if (data['title']) {
  //       this.title = this._translate.instant(data['title']);
  //     }
  //   });
  // }

  updateActiveNavbarItems(): void {
    this.collectRouteData(this._activatedRoute);
  }

  collectRouteData(route: ActivatedRoute): void {
    route.data.subscribe(data => {
      this.navbarItems?.forEach(item => {
        item.active = data['navbarItem'] === item.id;
      });

      if (data['title']) {
        const title = this._translate.instant(data['title']);
        this.title = title;
      }

      if (route.firstChild) {
        this.collectRouteData(route.firstChild);
      }
    });
  }

  navigate(routerLink: string[]): void {
    this._router.navigate(routerLink).then(r => {
      !r && console.error('Navigation failed in navbar.component/navigate');
    });
  }

  getUsername(): string {
    const user = this._authService.getUser();

    let username = '';
    if (user?.firstname) {
      username += user.firstname + ' ';
    }
    if (user?.lastname) {
      username += user.lastname;
    }

    return username;
  }

  onSidebarMenuToggle(): void {
    this.sidebarMenuVisible.update(prev => !prev);
  }

  onCompanyClick(): void {
    this._router.navigate(['/company/edit']).then(r => {
      !r && console.error('Navigation failed in footer.component/onClick');
    });
  }

  updateLocale(locale: string): void {
    const currentUser = this._authService.getUser();

    this.updateUserProfile.mutate({
      firstname: currentUser!.firstname,
      lastname: currentUser!.lastname,
      email: currentUser!.email,
      locale,
    } as User, {
      onSuccess: () => {
        // document.location.reload();
      },
      onError: (error: HttpErrorResponse) => {
        console.error('Error on NavbarComponent/onChangeLocale: ', error);
        this._messageService.add({
          severity: 'error',
          summary: 'COMMON.ERRORS.TITLE',
          detail: 'OFFERS.ERRORS.' + error.error.data[0].message,
        });
      },
    });
  }
}
